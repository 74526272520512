import React from 'react'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Lead from '../components/header/Lead'

const NotFoundPage = () => (
  <Layout>
    <Header>
      <Lead
        title="Niet gevonden"
        content="<p>Deze pagina bestaat niet...</p>"
      />
    </Header>
  </Layout>
)

export default NotFoundPage
